export const DELIVERY_METODS = {
	COURIER: 'COURIER',
	PICKUP: 'PICKUP',
	ONSPOT: 'ONSPOT',
};
export const ORG_STATUS = {
	WORK: 'WORK',
	NOWORK: 'NOWORK',
	ONLINE_CLOSED: 'ONLINE_CLOSED',
	ONWORK: 'ONWORK',
	OPEN: 'OPEN',
	NODELIVERY: 'NODELIVERY',
	SEZONNOTWORK: 'SEZONNOTWORK',
} as const;

export const PAYMENT_METODS = {
	CASH: 'CASH',
	BYCARD: 'BYCARD',
	CARD: 'CARD',
};

export const DILIVERY_TIME_STATUS = {
	NODELIVERY: 'NODELIVERY',
	ONLIPICKUP: 'ONLIPICKUP',
};
