export const MAIN_ROUTE = '/';
export const ERROR_ROUTE = '/error';
export const CATALOGUE_ROUTE = '/catalogue';
export const AUTH_ROUTE = '/auth';
export const PROFILE_ROUTE = '/profile';
export const PERSONAL_ROUTE = '/personal';
export const DELIVERYADDRESSES_ROUTE = '/deliveryaddresses';
export const ORDERS_ROUTE = '/orders';
export const BASKET_ROUTE = '/basket';
export const ORDERPLACEMENT_ROUTE = '/orderplacement';
export const ORDERACCEPTED_ROUTE = '/orderaccepted';
